import React, { useEffect, useState } from 'react'

import Layout from '../components/layout'
import Anchor from '../components/anchor'
import SEO from '../components/seo'
import SoundCloud from '../components/soundcloud'

const Index = () => {
  const [data, setData] = useState({});

  useEffect(() => {
    (async() => {

      let { mixes } = await import('../data/mixes');

      const soundcloudMixes = mixes.filter(mix => mix.source === 'soundcloud');

      setData(soundcloudMixes.slice().sort().reverse()[0]);
    })();
  }, []);

  return (
    <Layout textblock={true}>
      <SEO title="Home" />
      <section className="item">
        <h1 className="uppercase">Black Quartz #020</h1>

        <p>
          Video artist and producer <Anchor href="https://soundcloud.com/prcls" external={true}>Prcls</Anchor> is a figure in Vienna's cultural life you sooner or later
          bump into. Whether it's the movies, concerts, exhibitions, or simply nightlife – chances are that you ran into him at some point. He is the latest contributor
          to our <Anchor className="uppercase" href="https://soundcloud.com/nutriot/sets/black-quartz-mixtapes" external={true}>Black Quartz</Anchor> mixtape series
          and it's a special one. In this one-hour selection, he reminds us of all the good music you cannot find on Spotify et al: records that sound better at
          different speeds and long-deleted SoundCloud finds.
        </p>

        <div className="item small">
            <SoundCloud {...data} />
        </div>

        <p>
          Sign up to our <Anchor href="http://eepurl.com/dE5CrP" external={true} title="Mailchimp Signup">newsletter</Anchor> to stay updated and get downloads of our mixes!
        </p>
      </section>
    </Layout>
  )
};

export default Index
